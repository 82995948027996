<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>

    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Crypto</nz-breadcrumb-item>
        </nz-breadcrumb>

        <button class="ms-auto" nz-button nzType="primary" (click)="openModalOrEditDrawer()"><span nz-icon
                nzType="plus"></span>Add
            Crypto</button>
    </div>

    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Ticker" [(ngModel)]="searchQuery"
                    (ngModelChange)="filterSymbols()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>

    <nz-card>
        <div class="table-responsive">


            <nz-table #quizTable [nzData]="allSymbols" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                        <th nzWidth="50px">Sr.no</th>
                        <th [nzSortFn]="sortByTicker">Crypto</th>
                        <th [nzSortFn]="sortByName">Name</th>
                        <th [nzSortFn]="sortByStatus">Status</th>
                        <th [nzSortFn]="sortByLogo">Logo</th>
                        <th [nzSortFn]="sortBySector">Sector</th>
                        <th [nzSortFn]="sortBySector">Relevant Tags</th>
                        <th [nzSortFn]="sortBySector">similar Symbols</th>
                        <th [nzSortFn]="sortByExchange">sector</th>
                        <th [nzSortFn]="sortByIndustry">Chains</th>
                        <th [nzSortFn]="sortByCreatedOn">Created On</th>
                        <th [nzSortFn]="sortByUpdatedOn">Updated On</th>
                        <th [nzSortFn]="sortByUpdatedBy">Updated By</th>
                        <th [nzSortFn]="sortByUpdatedAt">Updated At</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let quiz of quizTable.data; let i = index">
                        <tr (click)="openModalOrEditDrawer(quiz.symbol_id)">
                            <td>{{ i + 1 }}</td>
                            <td>{{ quiz.symbol }}</td>
                            <td>{{ quiz.name }}</td>
                            <td>{{ quiz.active_status_id === 1 ? 'Yes' : 'No' }}</td>
                            <td>
                                <img class="img-preview"
                                    [src]="quiz.symbol_logo ? (storageUrl + quiz.symbol_logo) : fallbackImage"
                                    alt="{{ quiz.symbol }} logo" style="width: 100px; height: auto;" />
                            </td>
                            <td>{{ quiz.sector }}</td>
                            <td>{{ quiz.relevant_tags_name }}</td>
                            <td>{{ quiz.similar_symbols_name }}</td>
                            <td>{{ quiz.sector }}</td>
                            <td>
                                <ng-container *ngFor="let chain of quiz.chains">
                                    {{ chain.chain_name }},
                                </ng-container>
                            </td>
                            <td>{{ quiz.created_by_name }}</td>
                            <td>{{ quiz.created_at | date: 'yyyy-MM-dd' }}</td>
                            <td>{{ quiz.updated_by_name }}</td>
                            <td>{{ quiz.updated_at | date: 'yyyy-MM-dd' }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="deleteRecord(quiz.symbol_id, 'symbol')" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-card>



    <nz-modal (nzOnOk)="submitCryptoForm()" [nzOkText]="isEditMode ? 'Update Crypto' : 'Add Crypto'" [nzWidth]="800"
        [(nzVisible)]="isVisible" nzTitle="{{isEditMode ? 'Edit Crypto' : 'Add Crypto'}}" (nzOnCancel)="handleCancel()">
        <ng-container *nzModalContent>
            <form id="add-form" nz-form nzLayout="vertical" [formGroup]="cryptoForm">
                <nz-tabset [(nzSelectedIndex)]="selectedTabIndex">
                    <nz-tab nzTitle="Info">
                        <div class="row">
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label nzFor="symbol">Crypto</nz-form-label>
                                    <nz-form-control nzErrorTip="Please enter Stock">
                                        <input required nz-input id="symbol" formControlName="symbol"
                                            placeholder="Enter Crypto" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label nzFor="name">Full name</nz-form-label>
                                    <nz-form-control nzErrorTip="Please enter full name">
                                        <input required nz-input id="name" formControlName="name"
                                            placeholder="Enter Full Name" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label>Crypto status?</nz-form-label>
                                    <nz-radio-group formControlName="active_status_id">
                                        <label nz-radio [nzValue]="1">Active</label>
                                        <label nz-radio [nzValue]="2">Inactive</label>
                                    </nz-radio-group>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <textarea formControlName="description" nz-input placeholder="Description"
                                    [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label nzFor="symbol">Website</nz-form-label>
                                    <nz-form-control nzErrorTip="Please enter Stock">
                                        <input required nz-input id="symbol" formControlName="website"
                                            placeholder="Enter Website" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label>Select Sector</nz-form-label>
                                    <nz-select [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch
                                        nzAllowClear nzPlaceHolder="Select Sector" formControlName="sector_id">
                                        <nz-option *ngFor="let sector of sectors" [nzValue]="sector.sector_id"
                                            [nzLabel]="sector.sector"></nz-option>
                                    </nz-select>
                                </nz-form-item>
                            </div>
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label>Select Similar Crypto</nz-form-label>
                                    <nz-select [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch
                                        nzAllowClear nzPlaceHolder="Select Similar Crypto"
                                        formControlName="similar_symbols">
                                        <nz-option *ngFor="let stocks of similarStocks" [nzValue]="stocks.symbol_id"
                                            [nzLabel]="stocks.symbol"></nz-option>
                                    </nz-select>
                                </nz-form-item>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <nz-form-item>
                                    <nz-form-label>Select Relevant Tags </nz-form-label>
                                    <nz-select [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch
                                        nzAllowClear nzPlaceHolder="Select  Relevant Tags"
                                        formControlName="relevant_tags">
                                        <nz-option *ngFor="let tag of tags;
                                            " [nzValue]="tag.tag_id" [nzLabel]="tag.tag_name"></nz-option>
                                    </nz-select>
                                </nz-form-item>
                            </div>
                            <div class="col-md-4">
                                <nz-form-label><span>DostExplains<sup>™</sup></span></nz-form-label>
                                <nz-select formControlName="dost_explains" [nzAllowClear]="true" nzMode="multiple"
                                    style="width: 100%;" nzPlaceHolder="Select DostExplains">
                                    <nz-option *ngFor="let item of dostExplanins" [nzValue]="item.course_id"
                                        [nzLabel]="item.title"></nz-option>
                                </nz-select>
                            </div>
                            <div class="col-md-4">

                                <nz-form-item>
                                    <nz-form-label>Select test yourself quiz</nz-form-label>
                                    <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzAllowClear
                                        nzPlaceHolder="Select Sector" formControlName="quiz_id">
                                        <nz-option *ngFor="let quiz of quizz" [nzValue]="quiz.id"
                                            [nzLabel]="quiz.title"></nz-option>
                                    </nz-select>
                                </nz-form-item>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <nz-form-item>
                                    <label for="fileInput" class="file-upload-container">
                                        <input type="file" (change)="onFileChange($event)"
                                            accept="image/png, image/jpeg, image/jpg" id="fileInput"
                                            style="display: none;" />
                                        <div>
                                            <span>Upload Image</span>
                                        </div>
                                    </label>
                                </nz-form-item>
                            </div>
                            <div class="col-md-4">
                                <div *ngIf="symbolLogoPreview">
                                    <img [src]="symbolLogoPreview" alt="Symbol Logo Preview" class="img-preview " />
                                    <button (click)="removeUploadedLogo()" nz-button nzDanger nzType="dashed"
                                        nzShape="circle"><span nz-icon nzType="delete"></span></button>
                                </div>
                            </div>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Chains" [nzDisabled]="!symbolId">
                        <div class="row">
                            <div class="col-md-4">
                                <nz-form-item>

                                    <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzAllowClear
                                        nzPlaceHolder="Select Chain" formControlName="chain_id">
                                        <nz-option *ngFor="let chains of chainList" [nzValue]="chains.chain_id"
                                            [nzLabel]="chains.chain"></nz-option>
                                    </nz-select>
                                </nz-form-item>
                            </div>
                            <div class="col-md-6">
                                <nz-form-item>
                                    <nz-form-control nzErrorTip="Please contact Address">
                                        <input required nz-input id="symbol" formControlName="contact_address"
                                            placeholder="Enter contact Address" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-2">
                                <button (click)="addChainContractAddress()" nzBlock nz-button nzType="primary">
                                    Add Chain
                                </button>
                            </div>

                        </div>

                        <nz-table #basicTable [nzData]="chainsContactList" [nzLoading]="tableLoading" nzBordered
                            nzSize="middle">
                            <thead>
                                <tr>
                                    <th nzWidth="50px">#</th>

                                    <th>Chain</th>
                                    <th>Contact Address</th>
                                    <th>Primary</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                    <th nzWidth="100px" nzAlign="center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of chainsContactList; let i = index">
                                    <td>{{ i + 1 }}</td>

                                    <td>{{ log.chain }}</td>
                                    <td>{{ log.contact_address }}</td>
                                    <!-- <td>{{ log.is_primary ? 'Yes' : 'No' }}</td> -->
                                    <td (click)="$event.stopPropagation()">
                                        <div>
                                            <input (change)="setAsprimary(log.log_id)" class="form-check-input"
                                                type="checkbox" [checked]="log.is_primary" id="flexCheckDefault">
                                        </div>
                                    </td>
                                    <td>{{ log.created_by }}</td>
                                    <td>{{ log.created_at | date: 'short' }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="deleteRecord(log.log_id, 'contact')" (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>

                    </nz-tab>
                </nz-tabset>

            </form>
        </ng-container>
    </nz-modal>
</ng-template>