<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a routerLink="/"><span nz-icon nzType="home"
                    nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Courses</nz-breadcrumb-item>
    </nz-breadcrumb>

   
  
    <div class="row">
      <h2>All Courses</h2>
      <div class="col-md-3 my-3" *ngFor="let item of coursesList">
        <div class="card" (click)="showModal()" 
          style="width:100%; height: 100%; border-radius: 20px;">
          <img class="latest_article_image" alt="example" [src]="item.course_thumbnail_img ? (storageUrl + item.course_thumbnail_img) : 'path_to_fallback_image'" />
          <div class="card-body">
            <span
              class="inline-flex p-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{item.category_name}}</span>
            <div class="title_text">
              <p style="margin-bottom: 0px; font-family: Heading;">{{item.title | truncate:50 | titlecase}}</p>
            </div>
            <div class="description_text">
              <p style="margin-top: 0px;font-size: 14px;">{{item.description | truncate:80}}</p>
            </div>
            <div class="card-footer">
              <div class="d-flex bd-highlight mb-3">
                <div class="p-2 bd-highlight">
                  <h5>{{item.created_by_string}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nz-modal [(nzVisible)]="isVisible" nzTitle="The first Modal" >
      <ng-container *nzModalContent>
        <p>Content one</p>
        <p>Content two</p>
        <p>Content three</p>
      </ng-container>
    </nz-modal>
</app-main-fiminize-layout>