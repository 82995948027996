import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateAddress'
})
export class ContactAddressPipe implements PipeTransform {

  transform(address: string): string {
    if (!address) return '';
    const start = address.slice(0, 5);   // Get the first 5 characters
    const end = address.slice(-5);       // Get the last 5 characters
    return `${start}...${end}`;          // Concatenate the result
  }

}
