import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticker-requests',
  templateUrl: './ticker-requests.component.html',
  styleUrls: ['./ticker-requests.component.scss']
})
export class TickerRequestsComponent implements OnInit{

tickerRequestList:any[]=[]
tableLoading = true;
searchValue: string = ''; 
filteredTickerRequestList: any[] = [];  
  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}
  ngOnInit(): void {
    this.fetchAllTickerRequests();
  }
  fetchAllTickerRequests(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_ticker_request`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.tickerRequestList = response;
          this.filteredTickerRequestList = response; 
         
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  search(): void {
    // Filter tickerRequestList based on searchValue (matching request text)
    this.filteredTickerRequestList = this.tickerRequestList.filter(item =>
      item.request.toLowerCase().includes(this.searchValue.toLowerCase()) ||
      item.symbol.toLowerCase().includes(this.searchValue.toLowerCase()) ||
      item.request_by.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }
}
