import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { forkJoin, map } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FAQsComponent implements OnInit {
  isFAQModalVisible = false; // Modal visibility
  modalOkLoading = false; // For Ok button loading state
  isEditMode = false;
  isFAQFormVisible = false;
  selectedTabIndex: number = 0;
  categoryList: any[] = [];
  similarCryptos: any[] = []; // To track if the modal is in edit mode
  similarStocks: any[] = [];
  tableLoading = true;
  isModalVisible: boolean = false;
  faqListOpen: boolean = false;
  selectedTab: number = 1;
  searchQuery: string = '';
  allTickers: any[] = [];
  FAQlist: any;
  faqList: any[] = [];
  filteredFaqList = [...this.faqList];
  userID = localStorage.getItem('userId');

  currentFAQId: number | null = null;
  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}
  ngOnInit(): void {
    this.fetchAllFAQs(1);
  }
  FAQForm: FormGroup = new FormGroup({
    question: new FormControl('', Validators.required),
    answer: new FormControl('', Validators.required),
    symbol_id: new FormControl('', Validators.required),
    category_id: new FormControl(null),
    FAQ_id: new FormControl(''),
    user_id: new FormControl(this.userID),
  });
  filterFAQs(): void {
    this.filteredFaqList = this.faqList.filter((faq) =>
      faq.symbol.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
  fetchAllFAQs(categoryId: number): Promise<void> {
    this.tableLoading = true;
    const requestData = { category_id: categoryId };
    const apiUrl = `${environment.API_URL}fetch_all_FAQ`;

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          if (Array.isArray(response)) {
            this.faqList = response; // Populate faqList
            this.filterFAQs(); // Apply initial filter based on searchQuery
          } else {
            console.warn('Unexpected response format:', response);
            this.filteredFaqList = [];
          }
        },
        error: (error) => {
          console.error('Error:', error);
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }

  dynamicDeleteFunction(item: any, api: any, fieldName: any) {
    const apiUrl = `${environment.API_URL}${api}`;

    const requestData = { [fieldName]: item[fieldName] };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Handle success response
          if (fieldName === 'symbol_id') {
            this.fetchAllFAQs(this.selectedTabIndex === 0 ? 1 : 2);
          } else {
            this.openAllFAQlist(item.symbol_id);
          }
          this.message.success('FAQ deleted'); // Success message after API call
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: () => {
        this.message.error('Error occurred, please try later');
      },
    });
  }

  openAllFAQlist(symbolId: number): void {
    this.faqListOpen = true;
    this.fetchData();
    this.FAQForm.patchValue({ symbol_id: symbolId });
    if (symbolId) {
      const apiUrl = `${environment.API_URL}fetch_faq_questions`;
      const requestData = { symbol_id: symbolId };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.FAQlist = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        },
      });
    }
  }

  enableEdit(faq: any): void {
    faq.isEditing = true;
    this.isEditMode = true;
    this.FAQForm.patchValue(faq);
  }
  openFAQModal(): void {
    this.fetchData();
    this.isFAQModalVisible = true;
  }

  addSymbol(): void {
    this.modalOkLoading = true;

    const requestData = {
      user_id: 1, // Adjust user_id dynamically if needed
      category_id: this.selectedTabIndex === 0 ? 1 : 2, // Set category_id based on selected tab
      symbol_id: this.FAQForm.value.symbol_id,
    };

    const apiUrl = `${environment.API_URL}add_faq_symbol`;
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Ticker Added successfully');
        this.fetchAllFAQs(1);
        this.isFAQModalVisible = false;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
        this.modalOkLoading = false;
      },
    });
  }

  fetchData(): Promise<void> {
    this.tableLoading = true;
    const stocksUrl = `${environment.API_URL}stocks_dropdown`;
    const cryptosUrl = `${environment.API_URL}cryptos_dropdown`;
    const categoryUrl = `${environment.API_URL}category_dropdown`;

    return new Promise((resolve, reject) => {
      forkJoin({
        similarStocks: this.http.get(stocksUrl),
        similarCryptos: this.http.get(cryptosUrl),
        categoryList: this.http.get(categoryUrl),
      })
        .pipe(
          map((response: any) => {
            this.similarStocks = response.similarStocks;
            this.similarCryptos = response.similarCryptos;
            this.categoryList = response.categoryList;
          })
        )
        .subscribe({
          next: () => {
            this.tableLoading = false;
            resolve();
          },
          error: (error) => {
            console.error('Error:', error);
            this.tableLoading = false;
            reject();
          },
        });
    });
  }

  handleCancel(): void {
    this.isFAQModalVisible = false;
    this.isModalVisible = false;
    this.faqListOpen = false;
    this.isEditMode = false;
    this.FAQForm.reset();
  }
  isSymbolIdDisabled(symbol_id: number): boolean {
    return this.faqList.some((faq) => faq.symbol_id === symbol_id);
  }
  cancel(): void {}

  handleOk(): void {
    const apiUrl = this.isEditMode
      ? `${environment.API_URL}edit_FAQ`
      : `${environment.API_URL}add_FAQ`;
    this.FAQForm.controls['FAQ_id']?.[this.isEditMode ? 'enable' : 'disable']();
    this.FAQForm.controls['category_id']?.setValue(
      this.selectedTabIndex === 0 ? 1 : 2
    );
    const requestData = {
      ...this.FAQForm.value,
      user_id: this.userID, // Include user_id from localStorage
    };

    // Call the API
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success(
            this.isEditMode
              ? 'FAQ updated successfully'
              : 'FAQ added successfully'
          );
          this.isFAQModalVisible = false;
          this.openAllFAQlist(this.FAQForm.get('symbol_id')?.value);
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.FAQForm.reset();
        this.isFAQFormVisible = false;
      },
    });
  }
}
