import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-authors',
  templateUrl: './all-authors.component.html',
  styleUrls: ['./all-authors.component.scss']
})
export class AllAuthorsComponent implements  OnInit {
  id: number = 0;
  authorCoveringList: any[] = [];
  storageUrl = environment.STORAGE_URL;
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private fetchApiService: FetchApiService
  ) {
    
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = +(params.get('id') || 0);
      if (this.id) {
        this.fetchauthorcoveringtags();
      }
    });
  }
  fetchauthorcoveringtags() {
    const apiUrl = `${environment.API_URL}fetch_author_covering_tags`;
    const requestBody = { tag_id: this.id };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.authorCoveringList = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }


}
