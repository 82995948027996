<app-main-fiminize-layout>
  <div class="container">
    <nz-spin [nzSpinning]="isLoading" nzSimple></nz-spin>
  </div>
  <div class="container py-4" *ngIf="!isLoading">
    <div class="profile-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <nz-avatar [nzSize]="100" class="progress-wrapper" *ngIf="dostUserID !== userID"
            [nzSrc]="userDetails.profile_pic ? storageUrl + '/' + userDetails.profile_pic : 'assets/dummy-images/dummy_user_profile.jpg'"
            [nzShape]="'circle'">
          </nz-avatar>
          <div class="progress-wrapper" *ngIf="dostUserID == userID"
            (click)="dostUserID == userID ? showModal() : null">
            <nz-progress nzStrokeLinecap="round" nzType="circle"
              [nzPercent]="userDetails.profile_completion"></nz-progress>

            <nz-avatar [nzSize]="100" class="progress-image"
              [nzSrc]="userDetails.profile_pic ? storageUrl + '/' + userDetails.profile_pic : 'assets/dummy-images/dummy_user_profile.jpg'"
              [nzShape]="'circle'">
            </nz-avatar>
          </div>
        </div>
        <div class="col">
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <h2 class=" mb-1">{{ userDetails?.name }}</h2>

              <p class="text-muted mb-2">{{ userDetails?.username }}</p>

              <div class="stats">
                <span>{{userDetails.followers}} </span> Followrs
                <span class="mx-2">•</span>
                <span>{{userDetails.following}}</span> Following

              </div>

            </div>
            <div class="d-flex gap-2">
              <button *ngIf="dostUserID !== userID" (click)="followButton()" nz-button nzType="default"> {{
                userDetails.already_following ? 'Unfollow'
                : 'Follow' }}</button>
              <button *ngIf="dostUserID === userID" (click)="edituserProfile(userDetails)" nz-button
                nzType="default">Edit Profile</button>
              <nz-button-group *ngIf="dostUserID !== userID" nz-dropdown [nzDropdownMenu]="menu2">
                <button nz-button>Notify Me</button>
                <button nz-button nzPlacement="bottomRight">
                  <span nzTheme="fill" nz-icon nzType="bell"></span>
                </button>
              </nz-button-group>

              <nz-dropdown-menu #menu2="nzDropdownMenu">
                <ul nz-menu>
                  <li nzMode="multiple" *ngFor="let contentlist of notificationContnetList" nz-menu-item>
                    <label nz-checkbox [(ngModel)]="contentlist.selected">
                      {{ contentlist.content }}
                    </label>
                  </li>

                </ul>
                <button class="d-flex justify-content-center" nz-button (click)="onNotifyContentClick()">Notify
                  Me</button>

              </nz-dropdown-menu>

              <button *ngIf="dostUserID !== userID" nz-dropdown [nzDropdownMenu]="menu3" nzPlacement="bottomRight"
                nz-button>•••</button>
              <nz-dropdown-menu #menu3="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item (click)="reportBlockMuteuser(1, 'Block')"><span class="me-2" nz-icon nzType="stop"
                      nzTheme="fill"></span>{{ userDetails.block_user ? 'Unblock' : 'Block' }} </li>
                  <li nz-menu-item (click)="reportBlockMuteuser(2, 'Mute')"> <span class="me-2" nz-icon
                      nzType="eye-invisible" nzTheme="fill"></span> {{ userDetails.mute ? 'Unmute' : 'mute' }}</li>
                  <li nz-menu-item (click)="reportBlockMuteuser(3, 'Report')"><span class="me-2" nz-icon
                      nzType="exclamation-circle" nzTheme="fill"></span> {{ userDetails.report ? 'Unreport' : 'report' }}</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">

        <nz-tabset>
          <nz-tab [nzTitle]="titleTemplate">
            <ng-template #titleTemplate>
              <span>DostDiary<sup>™</sup></span>
            </ng-template>
            <ng-template nz-tab>
              <div class="card border-light" *ngFor="let post of posts"> 
                <div class="card-header"
                [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
                <h6 class="card-subtitle text-muted">{{ post.created_at | date: 'MMMM yyyy' }}</h6>
              </div>
              <div class="card-body">
                <p [innerHTML]="post.caption"></p>
                <div class="d-flex align-items-center justify-content-between mt-1">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                      <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                        [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                    </button>
                    <span class="me-2"><b>{{ post.like_count }}</b></span>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>

                    </button>
                    <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                      <i class="fa-solid fa-share-nodes"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-bookmark"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-exclamation"></i>
                    </button>
                    <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                      <i class="fa-solid fa-lock"></i>
                    </button>
                  </div>
                </div>

                <div *ngIf="commentVisible[post.diary_post_id]">
                  <nz-comment nzAuthor="{{ post.created_by_name }}" nzDatetime="{{ post.created_at | date:'short' }}">
                    <nz-avatar nz-comment-avatar nzIcon="user"
                      nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                    <nz-comment-content>
                      <form [formGroup]="postForm">
                        <nz-input-group [nzSuffix]="suffixIconSearch">
                          <input type="text" nz-input formControlName="comment" placeholder="Enter post comment" />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                          <button type="submit" nz-button nzType="dashed" nzShape="circle"><span nz-icon
                              nzType="send"></span></button>
                        </ng-template>
                      </form>
                    </nz-comment-content>
                  </nz-comment>
                </div>
              </div>
              </div>
              <div  >
              
              </div>

            </ng-template>
          </nz-tab>
          <nz-tab [nzTitle]="dostPortfolio">
            <ng-template #dostPortfolio>
              <span>DostPortfolio<sup>™</sup></span>
            </ng-template>
            <ng-template nz-tab>
              <div class="table-responsive">
                <nz-tabset [(nzSelectedIndex)]="activeTabIndex"
                  (nzSelectedIndexChange)="fetchAuthorDostportfolio($event)">
                  <nz-tab nzTitle="Stocks"></nz-tab>
                  <nz-tab nzTitle="Cryptos"></nz-tab>
                </nz-tabset>
                <nz-table #basicTable [nzData]="watchlistTickers">
                  <thead>
                    <tr>
                      <th nzWidth="50%">{{ activeTabIndex === 0 ? 'Stock' : 'Crypto' }}</th>
                      <th nzWidth="120px">Updated</th>
                      <th nzWidth="120px">Sentiment</th>
                      <th nzWidth="120px">Risk</th>
                      <th nzWidth="120px">Horizon</th>
                      <th nzWidth="120px">Trade</th>
                      <th nzWidth="120px">Status</th>
                      <th *ngIf="dostUserID === userID" nzWidth="120px">Review Date</th>
                      <th *ngIf="dostUserID === userID" nzWidth="120px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ticker of watchlistTickers">
                      <td>
                        <nz-tag
                          [routerLink]="[ticker.ticker_category === 'Crypto' ? '/crypto' : '/stocks', ticker.symbol]"
                          class="symbol-item">
                          {{ ticker.symbol }}
                        </nz-tag>

                      </td>
                      <td>{{ ticker.date }}</td>
                      <td>{{ ticker.sentiment_name || '-' }}</td>
                      <td>{{ ticker.risk_name || '-' }}</td>
                      <td>{{ ticker.horizon_name || '-'}}</td>
                      <td>{{ ticker.trade_name || '-' }}</td>
                      <td>{{ ticker.is_public === 1 ? 'Public' : 'Private' }}</td>
                      <td *ngIf="dostUserID === userID">{{ ticker.review_date | date: 'yyyy-MM-dd' }}</td>
                      <td *ngIf="dostUserID === userID" nzAlign="center" (click)="$event.stopPropagation()">
                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                          nzPopconfirmTitle="Are you sure you want to delete this record?"
                          (nzOnConfirm)="deleteWatchlisTtickerId(ticker.watch_list_ticker_id)">
                          <span nz-icon nzType="delete"></span>
                        </button>

                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </div>
            </ng-template>
          </nz-tab>

          <nz-tab nzTitle="Achievements">
            <ng-template nz-tab>
              Achievements content
            </ng-template>
          </nz-tab>
        </nz-tabset>
      </div>
      <div class="col-md-4">

        <div class="card border-light mb-4">
          <div class="card-header ">
            <h2 class="card-title"> Bio</h2>
          </div>
          <div class="card-body">
            <p>{{ userDetails?.bio | truncate:100}}</p>
          </div>
        </div>

        <div class="card border-light mb-4">
          <div class="card-header ">
            <h2 class="card-title"> Likes</h2>
          </div>
          <div class="card-body">
            <div *ngFor="let intrest of userDetails.relevant_tags" class="d-inline-block me-2">
              <nz-tag nzColor="blue" class="mx-2 my-1 p-1 rounded" style="cursor: pointer;"
                [routerLink]="['/companytags', intrest.tag_id]">
                {{intrest.tag_name}}
              </nz-tag>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <nz-modal [nzWidth]="800" [(nzVisible)]="isVisible" nzTitle="User Questions" (nzOnCancel)="handleCancel()"
    (nzOnOk)="submitAnswers()">
    <ng-container *nzModalContent>
      <div class="container-fluid">
        <div *ngFor="let question of userQuestionAnsList; let i = index"
          class="question-container my-4 p-3 border rounded shadow-sm bg-light">
          <!-- Question Title -->
          <div class="mb-3">
            <h6 class="font-weight-bold">
              {{ i + 1 }}. {{ question.user_question }} <span style="color: red;"
                *ngIf="question.mandatory_question">*</span>
            </h6>
          </div>
          <div *ngIf="question.question_type_id === 1">
            <nz-radio-group [(ngModel)]="question.already_answered_option">
              <div class="row">
                <div class="col-6" *ngFor="let option of question.options">
                  <label nz-radio [nzValue]="option.user_option_id" class="w-100">
                    {{ option.user_option }}
                  </label>
                </div>
              </div>
            </nz-radio-group>
          </div>
          <!-- Checkboxes for "Choose Multiple Option (Text Based)" -->
          <div *ngIf="question.question_type_id === 2">
            <div class="row">
              <div class="col-6" *ngFor="let option of question.options">
                <label nz-checkbox [(ngModel)]="option.selected"
                  [ngModel]="isOptionSelected(question.question_id, option.user_option_id)" class="w-100">
                  {{ option.user_option }}
                </label>
              </div>
            </div>
          </div>







          <!-- Textarea for "Short Answer" -->
          <div *ngIf="question.question_type_id === 3" class="mt-3">
            <textarea nz-input [(ngModel)]="question.already_answered" class="form-control" rows="3"
              placeholder="Enter your answer here"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-modal>

  <nz-modal [(nzVisible)]="editUserProfile" nzTitle="Edit User Profile" (nzOnCancel)="handleCancel()"
    (nzOnOk)="submitUserProfile()">
    <ng-container *nzModalContent>
      <form id="symbol-form" nz-form nzLayout="vertical" [formGroup]="edituserForm">
        <!-- Bio field -->
        <div class="row">
          <div class="col-md-4">

            <nz-form-item>
              <label for="fileInput" class="file-upload-container">
                <input formControlName="profile_pic" type="file" (change)="onFileChange($event)"
                  accept="image/png, image/jpeg, image/jpg" id="fileInput" style="display: none;" />
                <div>
                  <span>Upload Image</span>
                </div>
              </label>
            </nz-form-item>
          </div>
          <div class="col-md-6 d-flex justify-content-center align-items-center">
            <img [src]="userProfileImg ? userProfileImg : 'assets/dummy-images/dummy_user_profile.jpg'"
              alt="Profile Image Preview" class="rounded-profile-img" />
          </div>

          <div class="col-md-2">
            <button (click)="removeUploadedLogo()" nz-button nzDanger nzType="dashed" nzShape="circle">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
        </div>

        <nz-form-item>
          <nz-form-label [nzFor]="'bio'">Bio</nz-form-label>

          <nz-form-control>
            <textarea nz-input formControlName="bio"
              placeholder="Autosize height with minimum and maximum number of lines"
              [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>

        <!-- Name field -->
        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label [nzFor]="'name'">Name</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="name" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label [nzFor]="'Username'">Username</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="username" />
              </nz-form-control>
            </nz-form-item>

          </div>
        </div>

      </form>
    </ng-container>
  </nz-modal>
</app-main-fiminize-layout>