<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>DostPortfolio</nz-breadcrumb-item>
  </nz-breadcrumb>
  <div class="container-fluid mt-3">
    <button nz-button nzType="primary" (click)="openDrawer()">Add Ticker</button>
    <div class="table-responsive">
      <nz-tabset [(nzSelectedIndex)]="activeTabIndex" (nzSelectedIndexChange)="onTabChange($event)">
        <nz-tab nzTitle="Stocks"></nz-tab>
        <nz-tab nzTitle="Cryptos"></nz-tab>
      </nz-tabset>
      <nz-table #basicTable [nzData]="watchlistTickers">
        <thead>
          <tr>
            <th nzWidth="50%">{{ activeTabIndex === 0 ? 'Stock' : 'Crypto' }}</th>
            <th nzWidth="30%"></th>
            <th nzWidth="120px">Updated</th>
            <th nzWidth="120px">Sentiment</th>
            <th nzWidth="120px">Risk</th>
            <th nzWidth="120px">Horizon</th>
            <th nzWidth="120px">Trade</th>
            <th nzWidth="120px">Status</th>
            <th nzWidth="120px">Review Date</th>
            <th nzWidth="120px">Action</th>
          </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr *ngFor="let ticker of watchlistTickers" cdkDrag>
            <td>
              <nz-tag [nzColor]="ticker.ticker_category === 'Stock'  ? 'green' : 'pink'" class="mx-2 rounded"
                [routerLink]="[ticker.ticker_category === 'Crypto' ? '/crypto' : '/stocks', ticker.symbol]">
                {{ ticker.symbol }}
              </nz-tag>
            </td>
            <td>
              <button (click)="postForm.controls['symbol_id'].setValue(ticker.symbol_id)"
                nzTooltipTitle="Add To Dostdiary™" nzTooltipPlacement="rightBottom" nz-button nz-tooltip nz-popover
                nzPopoverTitle="{{ ticker.symbol }}" [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click"
                nz-button nzType="default" nzShape="circle">
                <span style="font-size: 1rem;" nz-icon nzType="plus-circle"></span>
              </button>
              <button nzTooltipPlacement="rightBottom" nz-tooltip nzTooltipTitle="Add To DostAlert™"
                (click)="dostAlertForm.controls['symbol_id'].setValue(ticker.symbol_id)" nz-button nzType="default"
                (click)="openAddDostAlertModal()" nzShape="circle"><span nz-icon nzType="alert"
                  nzTheme="fill"></span></button>
              <ng-template #contentTemplate>
                <form [formGroup]="postForm" (ngSubmit)="addPost()">
                  <div class="row">
                    <div class="col-md-12">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Enter Caption">
                          <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="caption"
                            placeholder="Enter post caption..." style="width: 100%;"></quill-editor>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <nz-radio-group formControlName="is_featured_post">
                        <label nz-radio [nzValue]="0">Public</label>
                        <label nz-radio [nzValue]="1">Private</label>
                      </nz-radio-group>
                    </div>
                    <div class="col-md-6">
                      <nz-form-item *ngIf="activeTabIndex === 0">
                        <nz-select class="w-100" nzAllowClear nzShowSearch nzPlaceHolder="Select Stocks"
                          formControlName="symbol_id">
                          <nz-option *ngFor="let stocks of stockCryptoData" [nzValue]="stocks.symbol_id"
                            [nzLabel]="stocks.symbol" [nzDisabled]="watchlist.includes(stocks.symbol_id)">
                          </nz-option>
                        </nz-select>
                      </nz-form-item>

                      <nz-form-item *ngIf="activeTabIndex === 1">
                        <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Crypto"
                          formControlName="symbol_id">
                          <nz-option *ngFor="let cryptos of stockCryptoData" [nzValue]="cryptos.symbol_id"
                            [nzDisabled]="watchlist.includes(cryptos.symbol_id)" [nzLabel]="cryptos.symbol"></nz-option>
                        </nz-select>
                      </nz-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <button type="submit" nz-button nzType="dashed" type="submit" nz-button nzType="primary"
                        nzBlock>Add Post </button>

                    </div>
                  </div>
                </form>
              </ng-template>
            </td>
            <td>{{ ticker.date }}</td>
            <td>{{ ticker.sentiment_name || '-' }}</td>
            <td>{{ ticker.risk_name || '-' }}</td>
            <td>{{ ticker.horizon_name || '-'}}</td>
            <td>{{ ticker.trade_name || '-' }}</td>
            <td>{{ ticker.is_public === 1 ? 'Public' : 'Private' }}</td>
            <td>{{ ticker.review_date | date: 'yyyy-MM-dd' }}</td>
            <td nzAlign="center" (click)="$event.stopPropagation()">
              <button *ngIf="['1', '2','4' , '5'].includes(roleId)" nz-button nzType="default" nzShape="circle"
                (click)="openDrawer(ticker)">
                <span nz-icon nzType="edit" nzTheme="fill"></span>
              </button>
              <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this record?"
                (nzOnConfirm)="deleteWatchlisTtickerId(ticker.watch_list_ticker_id)">
                <span nz-icon nzType="delete"></span>
              </button>


            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="py-4">

      <h2 class="mb-4">DostAlert<sup>™</sup></h2>
      <nz-tabset nzType="card" (nzSelectedIndexChange)="onAlertTypeChange($event)">
        <nz-tab nzTitle="Fundamental "></nz-tab>
        <nz-tab nzTitle="Technical "></nz-tab>
      </nz-tabset>
      <div *ngIf="!dostPortfolioAlertList || dostPortfolioAlertList.length === 0">
        <div class="alert alert-warning shadow-sm">
          <p class="mt-3 mb-0" style="font-size: 1rem; font-weight: bold; color: #333;">
            No alerts available at the moment.
          </p>
        </div>
      </div>
      <div class="row g-4" *ngIf="dostPortfolioAlertList && dostPortfolioAlertList.length > 0">
        <div *ngFor="let alertlist of dostPortfolioAlertList" class="col-md-3">
          <div class="card shadow-sm border-light d-flex flex-column h-100">
            <div class="card-body" (click)="openDostAlertModal(alertlist)">
              <nz-tag [nzColor]="activeTabIndex === 0 ? 'green' : 'pink'">{{ alertlist.symbol }}</nz-tag>
              <p style="margin-bottom: 0px; font-family: Heading;">
                {{ alertlist.text || 'No Text Available' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4" *ngIf="dostportfolioArtilces && dostportfolioArtilces.length > 0">
      <h2 class="mb-4">Articles</h2>
      <div class="row">
        <div class="col-md-3 mb-4" *ngFor="let article of dostportfolioArtilces">
          <div class="card" style="width: 100%; height: 380px; border-radius: 20px;">
            <img class="article-image" alt="example"
              [src]="article.featured_img_path ? (storageUrl + article.featured_img_path) : 'assets/profile-images/profile-image.png'" />

            <div class="card-body" [routerLink]="['/article', article.slug, article.id]">
              <span *ngIf="article.category_string" style="cursor: pointer;"
                class="inline-flex p-1 articles-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                {{article.category_string}}
              </span>

              <p style="margin-bottom: 0px; font-family: Heading;">
                {{ article.title | truncate: 50 | titlecase }}
              </p>

              <div class="description_text">
                <p style="margin-top: 0px;font-size: 14px;">{{article.meta_description | truncate:50}}</p>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex bd-highlight mb-3">
                <div class="p-2 bd-highlight">
                  <span style="cursor: pointer;" [routerLink]="['/author-details', article.created_by]"
                    class="d-flex align-items-center">
                    <nz-avatar
                      [nzSrc]="article.profile_pic ? (storageUrl + article.profile_pic) : 'assets/dummy-images/dummy_user_profile.jpg'"
                      nzSize="small" nzIcon="user" class="me-2"></nz-avatar>
                    <h5 class="mb-0 text-muted">{{article.created_by_name}}</h5>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <nz-modal [nzVisible]="drawerVisible" [nzTitle]="isEditMode ? 'Edit Ticker' : 'Add Ticker'" [nzFooter]="null"
    [nzWidth]="500" (nzOnCancel)="closeDrawer()">

    <ng-container *nzModalContent>
      <form [formGroup]="tickerForm" (ngSubmit)="saveTicker(isEditMode)">
        <nz-form-item *ngIf="activeTabIndex === 0">
          <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Stocks" formControlName="symbol_id">
            <nz-option *ngFor="let stocks of stockCryptoData" [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"
              [nzDisabled]="watchlist.includes(stocks.symbol_id)">
            </nz-option>
          </nz-select>
        </nz-form-item>

        <!-- Dropdown for Cryptos (Visible when activeTabIndex === 1) -->
        <nz-form-item *ngIf="activeTabIndex === 1">
          <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Crypto" formControlName="symbol_id">
            <nz-option *ngFor="let cryptos of stockCryptoData" [nzValue]="cryptos.symbol_id" [nzLabel]="cryptos.symbol"
              [nzDisabled]="watchlist.includes(cryptos.symbol_id)">
            </nz-option>
          </nz-select>
        </nz-form-item>


        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="sentiment_id" nzPlaceHolder="Select sentiment" id="sentiment">
                  <nz-option *ngFor="let sentiment of sentiments" [nzValue]="sentiment.sentiment_id"
                    [nzLabel]="sentiment.sentiment"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="trade_id" nzPlaceHolder="Select trade" id="trade">
                  <nz-option *ngFor="let trade of trades" [nzValue]="trade.trade_id"
                    [nzLabel]="trade.trade"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="risk_id" nzPlaceHolder="Select risk" id="risk">
                  <nz-option *ngFor="let risk of risks" [nzValue]="risk.risk_id" [nzLabel]="risk.risk"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="horizon_id" nzPlaceHolder="Select horizon" id="horizon">
                  <nz-option *ngFor="let horizon of horizons" [nzValue]="horizon.horizon_id"
                    [nzLabel]="horizon.horizon"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-radio-group formControlName="is_public">
                <label nz-radio [nzValue]="1">Public</label>
                <label nz-radio [nzValue]="0">Private</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-date-picker nzBlock formControlName="review_date" nzPlaceHolder="Select Review Date"
                [nzFormat]="'yyyy-MM-dd'" [ngStyle]="{ width: '100%' }"></nz-date-picker>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">

          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Would you like to set a reminder?"</nz-form-label>
              <nz-radio-group formControlName="reminder">
                <label nz-radio [nzValue]="1">Yes</label>
                <label nz-radio [nzValue]="0">No</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
        </div>

        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [disabled]="!tickerForm.valid">{{ isEditMode ? 'Update' : 'Add' }}
              Ticker</button>
          </nz-form-control>
        </nz-form-item>

      </form>
    </ng-container>
  </nz-modal>
  <nz-modal [nzFooter]="null" [(nzVisible)]="dostAlertVisible" nzTitle="DostAlert™" (nzOnCancel)="closeDrawer()">
    <ng-container *nzModalContent>
      <div class="card shadow-sm rounded border-0 mb-4">
        <div class="card-header bg-light border-bottom-0">
          <p class="card-title mb-0">{{ dostAlert.text }}</p>
        </div>
        <div class="card-body">
          <p [innerHTML]="dostAlert?.description"></p>
        </div>
        <div class="d-flex justify-content-between align-items-center px-3 py-2 border-top">

          <div class="d-flex align-items-center">
            <nz-avatar nzSize="small"
              [nzSrc]="dostAlert.profile_pic ? (storageUrl + dostAlert.profile_pic) : 'assets/profile-images/profile-image.png'"
              nzIcon="user"></nz-avatar>
            <b class="ms-2">{{ dostAlert?.created_by_name }}</b>
          </div>
          <div class="d-flex align-items-center">

            <i (click)="addTobookmark(dostAlert.dost_alert_id)" [class.fa-solid]="dostAlert.is_bookmark"
              [class.fa-regular]="!dostAlert.is_bookmark" class="fa-bookmark fa-lg me-2">
            </i>

            <span (click)="reportDostalert(dostAlert.dost_alert_id)" *ngIf="!dostAlert.already_reported" nz-icon
              nzType="exclamation-circle" nzTheme="outline">
            </span>

            <span *ngIf="dostAlert.already_reported" nz-icon nzType="exclamation-circle" nzTheme="fill">
            </span>
          </div>

        </div>

      </div>
    </ng-container>
  </nz-modal>
  <nz-modal [(nzVisible)]="isAddDostAlertModalVisible" nzTitle="Add DostAlert" (nzOnCancel)="closeDrawer()"
    (nzOnOk)="handleAdd()">

    <ng-container *nzModalContent>
      <form [formGroup]="dostAlertForm">
        <div class="row">
          <div class="col-md-12">
            <div nz-form-item>
              <div nz-form-label>
                <label for="text">Text</label>
              </div>
              <div nz-form-control>
                <textarea nz-input formControlName="text" placeholder="Enter alert text" nzAutosize></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div nz-form-label>
              <label for="text">Description</label>
            </div>
            <nz-form-item>
              <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="description"
                placeholder="Enter alert description" style="width: 100%;"></quill-editor>

            </nz-form-item>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <nz-form-item>

              <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select DostAlert Type"
                formControlName="alert_type_id">
                <nz-option *ngFor="let alertType of alertType" [nzValue]="alertType.type_id"
                  [nzLabel]="alertType.dost_alert_type"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item *ngIf="activeTabIndex === 0">
              <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Stocks"
                formControlName="symbol_id">
                <nz-option *ngFor="let stocks of stockCryptoData" [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"
                  [nzDisabled]="watchlist.includes(stocks.symbol_id)">
                </nz-option>
              </nz-select>
            </nz-form-item>

            <nz-form-item *ngIf="activeTabIndex === 1">
              <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Crypto"
                formControlName="symbol_id">
                <nz-option *ngFor="let cryptos of stockCryptoData" [nzValue]="cryptos.symbol_id"
                  [nzDisabled]="watchlist.includes(cryptos.symbol_id)" [nzLabel]="cryptos.symbol"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Add to DostDiary</nz-form-label>
              <nz-radio-group formControlName="is_diarypost">
                <label nz-radio [nzValue]="1">Yes</label>
                <label nz-radio [nzValue]="0">No</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>DostAlert<sup>™</sup> Status</nz-form-label>
              <nz-radio-group formControlName="active_status_id">
                <label nz-radio [nzValue]="1">Active</label>
                <label nz-radio [nzValue]="2">Inactive</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>
</app-main-fiminize-layout>