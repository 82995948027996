<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item>
            <a routerLink="/">
                <span nz-icon nzType="home" nzTheme="outline"></span>
            </a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>All Realted Question</nz-breadcrumb-item>
    </nz-breadcrumb>

    <div class="contianer">
        <div class="row">
            <div class="col-md-12">
                <div class="card border-light mb-4" *ngIf="quizSymbols && quizSymbols.length > 0">
                    <div class="card-header">
                        <h2 class="card-title">Test Yourself</h2>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li *ngFor="let question of quizSymbols ; let i = index"
                                class="list-group-item d-flex align-items-center" (click)="openQuestionModal(question)"
                                [ngClass]="{'bg-light': i % 2 !== 0}">
                                <div class="question-icon me-3">
                                    <span nz-icon [nzType]="question.symbol_quiz_answer === null ? 'question-circle' :
                                            question.is_answer_correct === 'correct' ? 'check-circle' : 'close-circle'"
                                        nzTheme="outline" [style.color]="question.symbol_quiz_answer === null ? 'black' : 
                                                (question.is_answer_correct === 'correct' ? 'green' : 'red')"></span>
                                </div>
                                <div>
                                    <small class="text-muted">QUESTION ASKED {{ question.created_at | date: 'dd MMM
                                        yyyy' }}</small>
                                    <p style="cursor: pointer;" class="mb-0">{{ question.question_text }}</p>
                                </div>
                            </li>
                        </ul>

                    </div>
              
                </div>
            </div>
        </div>
    </div>
    <nz-modal [nzFooter]="null" [(nzVisible)]="isVisible" nzTitle="Test Yourself" (nzOnCancel)="handleCancel()"
                        (nzOnOk)="handleOk()">
                        <ng-container *nzModalContent>
                            <div>
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <p><strong>{{ selectedQuestion?.question_text }}</strong></p>
                                    <span>Points: {{ selectedQuestion?.points }}</span>
                                </div>
                                <img *ngIf="selectedQuestion.question_image"
                                    [src]="selectedQuestion.question_image ? (storageUrl + selectedQuestion.question_image) : 'assets/profile-images/profile-image.png'"
                                     class="img-fluid" />
                    
                    
                                <p><strong>Options:</strong></p>
                                <nz-radio-group [(ngModel)]="selectedOption">
                                    <ng-container *ngFor="let option of selectedQuestion?.options">
                                      <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                          <div nz-radio [nzValue]="option.id">
                                            <span>{{ option.option_text }}</span>
                                            <span *ngIf="selectedOption === option.id && selectedQuestion?.is_answer_correct !== null">
                                              <span nz-icon [nzType]="option.is_correct_option === 1 ? 'check-circle' : 'close-circle'" 
                                                    [nzTheme]="'outline'" 
                                                    [style.color]="option.is_correct_option === 1 ? 'green' : 'red'">
                                              </span>
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </ng-container>
                                
                                    <button nz-button nzType="primary"
                                            [disabled]="!selectedOption || selectedQuestion?.is_answer_correct !== null"
                                            (click)="onOptionSelect(selectedOption)">Submit Answer</button>
                                  </nz-radio-group>
                    
                            </div>
                        </ng-container>
                    </nz-modal>
</app-main-fiminize-layout>