import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quiz-answer-details',
  templateUrl: './quiz-answer-details.component.html',
  styleUrls: ['./quiz-answer-details.component.scss'],
})
export class QuizAnswerDetailsComponent implements OnInit {
  tableLoading = true;
  userID = localStorage.getItem('userId');
 isQustionModalOpen = false;
  questionList: any[] = [];
  quizAnswers:any[]=[]
  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}

  ngOnInit(): void {
    this.fetchusersymbolanswer();
  }
  fetchusersymbolanswer(categoryId?: number): Promise<void> {
    this.tableLoading = true;
    this.tableLoading = true;
    const requestData = {
      category_id: categoryId??1,
    };

    const apiUrl = `${environment.API_URL}fetch_user_symbol_answer`;

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.questionList = response; // Assuming response is an array of FAQs
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error); // Provide the error to the reject
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }
  fetchsymbolanswers(item: any): Promise<void> {
    this.tableLoading = true; // Show loading spinner
    let obj={
      symbol_id:item.symbol_id, 
      user_id:item.user_id
    
    }
    const apiUrl = `${environment.API_URL}fetch_symbol_answers`;

    return new Promise((resolve, reject) => {
        this.http.post(apiUrl, obj).subscribe({
            next: (response: any) => {
              this.quizAnswers = response;  // Update questionList with the API response
            },
            error: (error) => {
                console.error('Error: ', error);
                this.tableLoading = false; // Hide loading spinner
                reject(error); // Reject the promise with error
            },
            complete: () => {
                this.tableLoading = false; // Hide loading spinner
                resolve(); // Resolve the promise
            },
        });
    });
}
  openModal(item: any): void {
    this.fetchsymbolanswers(item) 
        .then(() => {
            this.isQustionModalOpen = true; 
        })
        .catch((error) => {
            // Handle error if necessary
            console.error('Failed to fetch symbol answers', error);
        });
}


handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isQustionModalOpen = false;
}
}
