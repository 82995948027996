import { useAnimation } from '@angular/animations';
import {Subscription} from 'rxjs'
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-fiminize-layout',
  templateUrl: './fiminize-layout.component.html',
  styleUrls: ['./fiminize-layout.component.scss'],
})
export class FiminizeLayoutComponent implements OnInit {

  constructor(
    private fetchData: FetchApiService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {}
  colors: string[] = ['red', 'green', 'blue', 'purple', 'orange'];
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  authorCovering: any[] = [];
  roleId = localStorage.getItem('role_id');
  subscriptionPlanId=localStorage.getItem('subscription_plan_id');

  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();

  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }
  ngOnInit(): void {
    this.fetchPopularArticles();
    this.revFetchInitialData();
    this.fetchEditerPics();
    this.fetchTrendingArticles();
    this.fetchSponceredArticles();
    this.fetchAllArticles();
    this.fetchAllMarque();
   this.fetchAuthors()
    this.fetchArticlesByCategoris()
  }

  loadingData: boolean = false;
  latestArticleData: any = [];
  articlesCategoreisData:any=[];
  fetchPopularArticles(): Promise<any> {
  this.loadingData = true;
  
 let UserID=this.userId
    this.fetchData.getLatestArticles(UserID).subscribe({
      next: (response: any) => {
        console.log('Response: ', response.data);
        this.latestArticleData = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {},
    });
    return Promise.resolve(true);
  }
  fetchArticlesByCategoris(): Promise<any> {
    this.loadingData = true;
    
   let UserID=this.userId
      this.fetchData.getArticlesByCategories(UserID).subscribe({
        next: (response: any) => {
          console.log('Response: ', response.data);
          this.articlesCategoreisData = response.data;
        },
        error: (error) => {
          console.error('Error: ', error);
        },
        complete: () => {},
      });
      return Promise.resolve(true);
    }
  revLatestArticleData: any = [];
  revFetchInitialData(): Promise<any> {
    this.loadingData = true;
    let UserID=this.userId
    this.fetchData.getFeaturedArticles(UserID).subscribe({
      next: (response: any) => {
        this.revLatestArticleData = response.data.reverse();
        console.log('Response: ', this.revLatestArticleData);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }
  
  editerPics: any = [];
  fetchEditerPics(): Promise<any> {
    this.loadingData = true;
    let UserID=this.userId
    this.fetchData.getEditorsPicks(UserID).subscribe({
      next: (response: any) => {
        this.editerPics = response.data.reverse();
        console.log('Response: ', this.editerPics);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }
  
  trendingArticles: any = [];
  fetchTrendingArticles(): Promise<any> {
    this.loadingData = true;
    let UserID=this.userId
    this.fetchData.getTrendingArticles(UserID).subscribe({
      next: (response: any) => {
        this.trendingArticles = response.data.reverse();
        console.log('Response: ', this.trendingArticles);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }
  fetchAuthors() {
    const apiUrl = `${environment.API_URL}fetch_top_authors`;
    
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        this.authorCovering = response;
      },
      error: (err) => {
        console.error(err);
        
      },
    });
  }
  sponceredArticles: any = [];
  fetchSponceredArticles(): Promise<any> {
    let UserID=this.userId
    this.fetchData.getsponseredArticles(UserID).subscribe({
      next: (response: any) => {
        this.sponceredArticles = response.data.reverse();
        console.log('Response: ', this.sponceredArticles);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }
  allArtices: any = [];
  fetchAllArticles(): Promise<any> {
    let UserID=this.userId
    this.fetchData.getAllPublishedArticles(UserID).subscribe({
      next: (response: any) => {
        this.allArtices = response.data.reverse();
        console.log('Response: ', this.allArtices);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }
  markupLines: any[] = [];
  fetchAllMarque(): Promise<any> {
    this.fetchData.fetchAllMarkupLines().subscribe({
      next: (response: any) => {
        this.markupLines = response;
        console.log('Response: ', this.allArtices);
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.loadingData = false;
      },
    });
    return Promise.resolve(true);
  }

  getColor(index: number): string {
    return this.colors[index % this.colors.length];
  }
  navigateToArticle(articleId: number | null): void {
    if (articleId) {
      this.router.navigate(['/article', articleId]); // Assuming route '/article/:id' exists
    } else {
      console.warn('No article associated with this markup line.');
      // Optionally, you can handle cases where articleId is null or undefined.
    }
  }
}
