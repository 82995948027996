<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Author Management</nz-breadcrumb-item>
        </nz-breadcrumb>

    </div>
    <nz-card>
        <nz-input-group [nzPrefixIcon]="'search'">
            <input
              nz-input
              placeholder="Search Author Question"
              [(ngModel)]="searchText"
              (ngModelChange)="filterData()"
            />
          </nz-input-group>
        <nz-table #basicTable [nzData]="filteredAuthorsData" [nzBordered]="true">
            <thead>
              <tr>

         <th  nzWidth="50px" >Sr.no</th>
                <th nzWidth="150px">Author Name</th>
                <th nzWidth="150px">Author Username</th>
                <th nzWidth="200px">Author Question</th>
                <th nzWidth="200px">Answer</th>
                <th nzWidth="100px">Created At</th>
                <th nzWidth="100px">Updated At</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let author of basicTable.data;  let i = index">
                <td>{{i+1}}</td>
                <td>{{ author.author_name }}</td>
                <td>{{ author.author_username }}</td>
                <td>{{ author.author_question }}</td>
                <td>{{ author.answer || 'N/A' }}</td>
                <td>{{ author.created_at | date: 'dd MMM yyyy' }}</td>
                <td>{{ author.updated_at | date: 'dd MMM yyyy'}}</td>
              </tr>
            </tbody>
          </nz-table>
    </nz-card>
  
</ng-template>
