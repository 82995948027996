import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-flow',
  templateUrl: './signup-flow.component.html',
  styleUrls: ['./signup-flow.component.scss'],
})
export class SignupFlowComponent implements OnInit {
  step = 1;
  currentYear: number = new Date().getFullYear();
  headerLogo: SafeUrl;
  showSubCategoryForm = false; 
  categories: any[] = [];
  randomNum1 = this.generateRandomNumber();
  randomNum2 = this.generateRandomNumber();
  selectedCities: string[] = [];
  subCategories: any[] = [];
  isLoading: boolean = false; 
  imageName: string | null = null; 
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private http: HttpClient,
    private message: NzMessageService,
    private apiService: FetchApiService
  ) {
    this.headerLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/InvestDostFull.png'
    );
  }
  ngOnInit(): void {
    this.fetchCategories();
    this.fetchTopAuthors();
  }
  // Define the FormGroups and FormControls manually
  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  authForm = new FormGroup({
    authAnswer: new FormControl(null, [Validators.required]),
  });

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required]),
  });

  passwordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  usernameForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
  });
  categoryForm = new FormGroup({
    selectedCategories: new FormControl<number[]>([],[Validators.required, Validators.minLength(1)]
    ),
  });
  authorForm = new FormGroup({
    selectedAuthors: new FormControl<number[]>([], [Validators.required, Validators.minLength(1)]),
  });

  subCategoryForm = new FormGroup({
    selectedSubCategories: new FormControl<number[]>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
  });
  profileForm = new FormGroup({
    profilePicture: new FormControl<File | null>(null, [Validators.required]),

  });
  fetchCategories(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.categories = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {},
    });
    return Promise.resolve(true);
  }

  onCategoryChange(event: Event, categoryId: number) {
    const selectedCategories = this.categoryForm.controls['selectedCategories']
      .value as number[];
    if ((event.target as HTMLInputElement).checked) {
      // Add category ID if checked
      selectedCategories.push(categoryId);
    } else {
      const index = selectedCategories.indexOf(categoryId);
      if (index > -1) {
        selectedCategories.splice(index, 1);
      }
    }

    this.filterAndSortData(selectedCategories);
    this.categoryForm.controls['selectedCategories'].setValue(
      selectedCategories
    );
  }

  filterAndSortData(selectedCategories: number[]) {
    console.log(selectedCategories);

    const combinedArray = this.categories
      .filter((item) => selectedCategories.includes(item.id))
      .sort((a, b) => {
        return a.someProperty - b.someProperty;
      });

    this.subCategories = [...combinedArray];

    console.log('Sorted Subcategories: ', this.subCategories);
  }

  // onSubCategoryChange(event: Event, categoryId: number) {
  //   const selectedSubCategory =
  //     (this.subCategoryForm.controls['selectedSubCategories']
  //       .value as number[]) || [];

  //   if ((event.target as HTMLInputElement).checked) {
  //     if (!selectedSubCategory.includes(categoryId)) {
  //       selectedSubCategory.push(categoryId);
  //     }
  //   } else {
  //     const index = selectedSubCategory.indexOf(categoryId);
  //     if (index > -1) {
  //       selectedSubCategory.splice(index, 1);
  //     }
  //   }

  //   this.subCategoryForm.controls['selectedSubCategories'].setValue(
  //     selectedSubCategory
  //   );

  //   console.log(this.subCategoryForm.controls['selectedSubCategories'].value);
  // }
  onSubCategoryChange(event: Event, categoryId: number) {
    const selectedCategories = this.categoryForm.controls['selectedCategories']
    .value as number[];

    // const selectedSubCategory =(this.subCategoryForm.controls['selectedSubCategories'] .value as number[]) || [];

    if ((event.target as HTMLInputElement).checked) {
      if (!selectedCategories.includes(categoryId)) {
        selectedCategories.push(categoryId);
      }
    } else {
      const index = selectedCategories.indexOf(categoryId);
      if (index > -1) {
        selectedCategories.splice(index, 1);
      }
    }

    this.subCategoryForm.controls['selectedSubCategories'].setValue(
      selectedCategories
    );

    console.log(this.subCategoryForm.controls['selectedSubCategories'].value);
  }
  selectedAuthorData: number[] = [];
  onAuthorChange(event: any, authorId: number): void {
    if ((event.target as HTMLInputElement).checked) {
      // Add the author ID if the checkbox is checked
      this.selectedAuthorData.push(authorId);
    } else {
      // Remove the author ID if the checkbox is unchecked
      const index = this.selectedAuthorData.indexOf(authorId);
      if (index !== -1) {
        this.selectedAuthorData.splice(index, 1);
      }
    }
    console.log(this.selectedAuthorData);

    // Update the form control for selected authors
    this.authorForm.patchValue({
      selectedAuthors: this.selectedAuthorData
    });
  
    console.log('author Data' ,  this.authorForm.controls['selectedAuthors'].value);
  }
  
  imageUrl: string | null = null;
  onProfilePictureChange(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      this.profileForm.controls['profilePicture'].setValue(file); // Set the selected file

      // Create a URL for the selected file
      this.imageUrl = URL.createObjectURL(file); // Set the image URL
      this.imageName = file.name; // Set the image name
      console.log('Selected file:', file);
      console.log('Image URL:', this.imageUrl);
      console.log('Image Name:', this.imageName);
    } else {
      this.profileForm.controls['profilePicture'].setValue(null); // Reset to null if no file is selected
      this.imageUrl = null; // Reset the image URL
      this.imageName = null; // Reset the image name
    }
  }

  removeImage(): void {
    this.profileForm.controls['profilePicture'].setValue(null); // Clear the form control
    this.imageUrl = null; // Reset the image URL
    this.imageName = null; // Reset the image name

    // Reset the file input element
    const fileInput = document.getElementById('profilePicture') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the input value
    }
  }

  authors: any[] = [];

  fetchTopAuthors() {
    const apiUrl = `${environment.API_URL}fetch_top_authors`;
    this.http.get<any[]>(apiUrl).subscribe({
      next: (response: any[]) => {
        this.authors = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      }
    });
  }

  onSelectionChange(selectedIds: number[]): void {
    console.log('Selected User IDs:', selectedIds);
    // Perform any action based on the selected user IDs
  }
  generateRandomNumber() {
    return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
  }
  isSelected(categoryId: number): boolean {
    return this.categories.includes(categoryId);
  }
  nextStep() {
    if (this.validateCurrentStep()) {
      this.step++;
  } 
}

  validateOtp() {
    if (this.otpForm.valid) {
      this.submitForm();
      this.step++; // Call the submitForm method if OTP form is valid
    } else {
      this.message.error('Please enter a valid OTP.');
    }
  }
  sendOtpToUser() {
    const { name, email } = this.userForm.value;
    const apiUrl = `${environment.API_URL}register_as_user`;
    if (this.userForm.valid) {
      this.isLoading = true; 
      const validEmail = email ?? '';
      const validName = name ?? '';
      this.http.post(apiUrl, { name: validName, email: validEmail }).subscribe({
        next: (response: any) => {
          this.isLoading = false; 
          console.log(response);
          if (response.success) {
            // Store user information in sessionStorage
            sessionStorage.setItem('email', validEmail);
            sessionStorage.setItem('name', validName);
            this.message.success(`OTP sent to ${validEmail}.`); 
            this.nextStep(); // Move to the next step if OTP sent successfully
          } else {
            this.message.error(response.message);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.error('API error:', error);
          this.message.error('Failed to send OTP. Please try again.');
        },
      });
    } else {
      this.message.error('Please fill in all required fields.');
    }
  }
  submitForm() {
    let obj = {
      name: sessionStorage.getItem('name'),
      email: sessionStorage.getItem('email'),
      otp: this.otpForm.controls['otp'].value,
    };
    const apiUrl = `${environment.API_URL}verify_email_otp_for_user `;
    try {
      this.http.post(apiUrl, obj).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.message.success('OTP Verified');
            sessionStorage.setItem('userId', response.user_id);
            this.nextStep();
          } else {
            this.message.error('OTP not Verified');
          }
        },
        error: (error) => {},
      });
    } catch (error) {
      console.error('Error in API call:', error);
      this.message.error('Login failed');
    }
  }

  completeSignup() {
    // Prepare the payload using the data collected from the forms
    const userId = sessionStorage.getItem('userId'); // Get user ID from session storage
    const password:any = this.passwordForm.controls['password'].value; // Get password from form
    const username :any = this.usernameForm.controls['username'].value; // Get username from form
    const selectedAuthors :any = this.authorForm.controls['selectedAuthors'].value; // Get selected authors
    const profilePicFile  = this.profileForm.controls['profilePicture'].value; // Get profile picture file
    const CategoriesdData = this.subCategoryForm.controls['selectedSubCategories'].value; // Get selected categories



  console.log(CategoriesdData);
  
    // Create FormData for file upload
    const formData = new FormData();
    formData.append('user_id', userId ? userId : '');
    formData.append('password', password);
    formData.append('username', username);
    if(selectedAuthors.length>0){ formData.append('author_id',   JSON.stringify(selectedAuthors) ); }
    // formData.append('category_id',CategoriesdData );

    if (profilePicFile) {formData.append('profile_pic', profilePicFile);}
  
    const apiUrl = `${environment.API_URL}add_user_details`;
  
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Signup completed successfully!');
          // Navigate to the desired page or perform further actions
          this.router.navigate(['/some-path']); // Adjust the path as necessary
        } else {
          this.message.error(response.message || 'Failed to complete signup.');
        }
      },
      error: (error) => {
        console.error('Error while completing signup:', error);
        this.message.error('An error occurred while completing signup. Please try again.');
      },
    });
  }
  
  fakeApiCall(endpoint: string) {
    return new Promise<{ success: boolean }>((resolve) => {
      setTimeout(() => resolve({ success: true }), 1000);
    });
  }

  validateCurrentStep() {
    switch (this.step) {
      case 1:
        return this.userForm.valid;
      case 2:
        return this.authForm.valid;
      case 3:
        return this.otpForm.valid;
      case 4:
        return this.passwordForm.valid;
      case 5:
        return this.usernameForm.valid;
      case 6:
        return this.profileForm.valid;
        case 7:
        return this.categoryForm.valid;
        case 8:
          return this.subCategoryForm.valid;
      case 9:
        return this.authorForm.valid;
      default:
        return true;
    }
  }
}
