<app-main-fiminize-layout>
    <h1> More authors posting about #{{ authorCoveringList[0]?.tag_name }}</h1>
    <div class="container my-5">
        <div class="row g-4">
            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6" *ngFor="let authorList of authorCoveringList">
                <div class="card author-card h-100 shadow-sm">
                    <div class="card-body text-center">
      
                        <nz-avatar  [nzSize]="64" 
                        [nzSrc]="authorList.profile_pic ? (storageUrl + authorList.profile_pic) : 'assets/profile-images/profile-image.png'"  
                        nz-comment-avatar 
                        nzIcon="user">
                      </nz-avatar>
                        <h5 class="card-title">{{ authorList.name }}</h5>
                        <p class="card-text text-muted">
                            Followers: {{ authorList.followers_count }} Following: {{ authorList.following }}
                        </p>
                        <a href="#" class="btn btn-outline-primary btn-sm mt-1">View Profile</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-main-fiminize-layout>
