import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import Quill from 'quill';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  events: any[] = [];
  drawerVisibleAddEvent = false;
  drawerVisibleEditEvent = false;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe
  ) {}
  drawerVisible: boolean = false;
  isEditMode: boolean = false;
  
  eventForm: FormGroup = new FormGroup({
    event_id: new FormControl(null),
    event_title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    valid_date_range: new FormControl([]),
    active_status_id: new FormControl('', [Validators.required]),
    is_public: new FormControl(null),
  });
  ngOnInit(): void {

    this.fetchAllEvents();
  }
  fetchAllEvents() {
    this.http.get(`${environment.API_URL}fetch_event`).subscribe({
      next: (response: any) => {
        this.events = response;
      },
      error: (error) => {
        console.error('Error fetching comments:', error);
      }
    });
  }
  
  closeDrawer(): void {
    this.drawerVisible = false;
    this.eventForm.reset();
  }
  handleEventFormSubmit(): void {
    if (this.eventForm.invalid) {
      this.message.error('Please fill in all required fields.');
      return;
    }
  
    const { valid_date_range, ...formValues } = this.eventForm.value;
    const [start_date, end_date] = valid_date_range || [null, null];
  
    const eventObj = {
      ...formValues,
      valid_from: start_date ? this.datePipe.transform(start_date, 'yyyy-MM-dd') : '',
      valid_till: end_date ? this.datePipe.transform(end_date, 'yyyy-MM-dd') : '',
      updated_by: localStorage.getItem('userId'),
    };
  
    const apiUrl = this.isEditMode
      ? `${environment.API_URL}update_event`
      : `${environment.API_URL}add_event`;
  
    this.http.post(apiUrl, eventObj).subscribe({
      next: (response: any) => {
        this.message.success(this.isEditMode ? 'Event updated successfully!' : 'Event added successfully!');
        this.closeDrawer();
        this.fetchAllEvents(); // Refresh event list
      },
      error: (error) => {
        console.error(error);
        this.message.error('Failed to process the request.');
      },
    });
  }
 
  openDrawer(isEdit: boolean, eventData?: any): void {
    this.isEditMode = isEdit; // Sets the mode to edit or add
    this.drawerVisible = true; // Opens the drawer
  
    if (isEdit && eventData) {
      // Populate the form with the event data for editing
      this.eventForm.patchValue({
        event_id: eventData.event_id,
        event_title: eventData.event_title,
        description: eventData.description,
        valid_date_range: [new Date(eventData.valid_from), new Date(eventData.valid_till)],
        active_status_id: eventData.active_status_id,
        is_public: eventData.is_public,
      });
    } else {
      // Reset the form for adding a new event
      this.eventForm.reset();
    }
  }
    cancel(): void {
  }

  deleteItem(id: number | string): void {
    const apiUrl = `${environment.API_URL}delete_event`;
    const requestData = { event_id: id };
  
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (!response.success) {
          this.message.error('Failed to delete event');
          return;
        }
        this.message.success('Event deleted successfully');
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('An error occurred, please try again later');
      },
      complete: () => {
        this.fetchAllEvents();
      },
    });
  }

 
  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        // ['blockquote'],
        // [{ 'header': 1 }, { 'header': 2 }],
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],
        // [{ 'indent': '-1' }, { 'indent': '+1' }],
        // [{ 'direction': 'rtl' }],
        ['clean'],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],

      clipboard: {
        matchVisual: false,
      },
    },
  };
}
